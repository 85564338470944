<script>
import Navbar from "@/components/navbar-stable";
import Footer from "@/components/footer";
import AboutUs from "@/components/about-us-component"

/**
 * about-us component
 */
export default {
  name: "about-us",
  components: {
    Navbar,
    Footer,
    AboutUs,
  },
};
</script>

<template>
  <div>
    <Navbar/>
    <AboutUs/>
    <Footer/>
  </div>
</template>