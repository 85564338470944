<template>
  <div>
    <section class="section section-lg bg-web-desc">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center margin-t-70">
            <h2 class="text-white">Hakkımızda</h2>
          </div>
        </div>
      </div>
      <div class="bg-pattern-effect">
        <img src="@/assets/images/bg-pattern-light.png" alt />
      </div>
    </section>

    <section class="section bg-light margin-t-70" id="features">
      <div class="container">
        <div class="row vertical-content">
          <div class="col-lg-5">
            <div class="features-box">
              <h3>Biz Kimiz? Ne yapıyoruz?</h3>
              <p class="text-dark web-desc">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus eveniet quam minima deleniti molestiae! Laboriosam, doloremque vero accusamus quae explicabo quis eligendi reprehenderit eos officiis repudiandae, ad nam cupiditate tenetur?
              </p>
              <p class="text-dark web-desc">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est consectetur non ex, ipsum exercitationem obcaecati, iusto quam nobis dignissimos nostrum provident itaque corporis dolore enim. Dolores reiciendis id voluptas commodi.
              </p>
              <p class="text-dark web-desc">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta similique adipisci est quaerat blanditiis illum libero autem dolore molestias, veritatis consequuntur harum, aperiam recusandae facilis molestiae. Tempora, rem! Sed, accusantium.
              </p>              
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-img features-right text-right">
              <img
                src="@/assets/images/aboutus/about-us.svg"
                alt="about-us image"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

